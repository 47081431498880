import React from 'react';
import styled from 'styled-components';
import { useLinkComponent } from '@marty-js/design/src/utils/component';
import { LinkProps } from '@marty-js/design/src/atoms/types';
import { LinkData, PluginComponent } from './types';

const NavLink = (props: LinkProps) => {
  const Link = useLinkComponent();

  return <Link {...props} />;
};

const StyledNavLink = styled(NavLink)`
  color: var(--theme-color-primary);
  text-decoration: none;
`;

export const Link: PluginComponent = ({ children, data }) => {
  const nodeData = data as LinkData;
  if (!nodeData) {
    return null;
  }

  const rel: string[] = [];
  if (nodeData.relNofollow) {
    rel.push('nofollow');
  }
  const reg = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)/im;
  const whiteListReferer = ['clubic.com', 'clubi.cc', 'jeuxvideo.fr'];
  const matches = reg.exec(nodeData.href);
  if (matches && !whiteListReferer.includes(matches[1])) {
    rel.push('noreferrer');
  }

  if (typeof nodeData.rawHref === 'undefined') {
    nodeData.rawHref = true;
  }

  return nodeData.href ? (
    <StyledNavLink
      event={matches ? { type: 'click' } : null}
      href={nodeData.href}
      rawHref={nodeData.rawHref}
      rel={rel}
      openInNewWindow={nodeData.openInNewWindow ? true : null}
    >
      {children}
    </StyledNavLink>
  ) : (
    <span>{children}</span>
  );
};
